.circle{
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border:0px;
    margin:0px;
    .empty-circle {
    width: inherit;
    height: inherit;
    border: 1px solid #A3A6B1;
    border-radius: 50%;
    }
  }
.status-flex-container{
      min-height: 375px;
      width: 100%;
      display: flex;
      align-items: stretch;
      background-color: #f3f3f7;
}
.status {
  flex: 40;
  padding-top: 1%;
  padding-left: 1%;
}

.result {
  flex: 60;
  padding-top: 1%;
  padding-left: 1%;
}
