h5.status {
    padding-top: 10px;
    color: #252A2E;
    font-weight: normal;
    font-size: 14px;
}

h5.status-selected {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;
    padding-top: 10px;
    color: #252A2E;
}