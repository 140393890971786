.line {
    width: .0063rem;
    min-height: 2.75rem;
    background-color: #A3A6B1;
    margin-left: 1rem;
}

.columnitem {
    display: flex;
    width: 100%;
}