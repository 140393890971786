.diagTable {
  // margin: 15px;
  table-layout: fixed;
  border: 1px solid;
  width: 100%;

  .table, th, td {
    border: 1px solid;
  }
}

.diagnostics-xl {
  .modal-xl {
    max-width: 80%;
  }
}

.RAStatuscastWidgetFrame {
  width: 100%;
  height: 260px;
  margin-top: 10px;
  margin-bottom: -25px;
}

.App {

  .buttonWrap {
    white-space: normal;
  }

  .wordWrapInputResult {
    white-space: wrap;
    word-wrap: break-word !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left !important;
    width: 66%;
    height: 25%;
    border-style: solid;
    border-radius: inherit;
    border-width: 2px;

  }

  .shadow {
    display: flex;
    flex-direction: column;
    // min-width: 103.75%;
    flex-grow: 1;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid #fff;
    border-radius: 1rem;
  }

  .main {
    background-color: ghostwhite
  }

  .shadow-Column {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    max-width: 50%;
    word-wrap: break-word;
  }

  .row-info {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 15px;
  }

  .shadow-row-lower {
    padding: 10px;
    display: flex;
    min-height: 400px;
    max-height: 400px;
    border-radius: 1rem;
    border-width: 7px;
    max-width: 100%;
    min-width: 100%;
    box-shadow: 0 0 4px rgba(36, 35, 45, .3) !important;

    .title-remoteIT-info {
      margin-bottom: 0.5rem;
      font-family: Open sans-serif, sans-serif;
      font-size: large;
      //font-weight: 900;

    }

    .text-remote-info {
      font-size: 0.75rem;
      text-align: left;
      overflow-y: auto;
    }
  }

  .shadow-Column-left.col {
    padding-right: inherit;
    padding-left: 0%;
    padding-bottom: unset;

    .text-remote-info {
      .message-progress {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        /* font-size: .875rem; */
        // margin-bottom: 1rem;
        border-radius: 0.125rem;
        padding: 0.25rem;
        font-family: Open sans-serif, sans-serif;
        font-size: medium;
        font-weight: 700;
      }
    }
  }

  .shadow-Column-right.col {
    padding-right: 0%;
    /* padding-left: inherit; */
    padding-bottom: inherit;

    .text-remote-info {
      font-size: 1rem;
      text-align: left;
      overflow-y: auto;
      font-family: Open sans-serif, sans-serif;
      font-size: large;

      .message-progress {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        /* font-size: .875rem; */
        margin-bottom: 1rem;
        border-radius: 0.125rem;
        padding: 0.25rem;
        font-family: Open sans-serif, sans-serif;
        font-size: large;
      }
    }
  }

  .form-class {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    word-wrap: break-word;
    background-clip: border-box;

    .col-filter-switch {
      width: 20%;
      padding-left: 0px;
    }

    .btn-remoteIT {
      min-width: fit-content;
      text-align: center;
      vertical-align: middle;
      // margin-left: 4%;
      width: 100%;
      border-color: grey;
      border-radius: 0.5rem;
      font-family: Open sans-serif, sans-serif;
    }

    .btn-remoteITEventInfo {
      min-width: fit-content;
      text-align: center;
      vertical-align: top;
      // margin-left: 4%;
      width: 100%;
      border-color: grey;
      border-radius: 0.5rem;
      font-family: Open sans-serif, sans-serif;
    }

    .message-RemoteIT {
      display: flex;
      align-items: flex-start;
      vertical-align: middle;
      justify-content: flex-start;
      font-size: .875rem;
      border-radius: 0.125rem;
      padding: 0rem;
      margin-bottom: unset;
    }

    .row-remoteIT-top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 10px;
      margin-left: 10px;

      .row-switches {
        padding-left: 0%;
        margin-left: 30px;
      }

      .dropdown-local {
        font-family: Open sans-serif, sans-serif;
      }
    }
  }
}
  
  