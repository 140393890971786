.provisioning {
  .flex {
    display: flex;
    flex-direction: row;
    height: 3%;
    padding: 5px;
    margin: 0 -1rem;
    border-Bottom: 1px solid #ebebed;
    justify-Content: space-between;
    align-Items: center;
  }

  .flex>div {
    justify-content: center;
    line-height: 20px;
    font-size: 20px;
  }

  .flex-container {
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .flex-container>div {
    justify-content: center;
    line-height: 75px;
  }

  .prov-card {
    border-radius: 10px;
    margin-left: 2%;
    margin-right: 2%;
  }

  .card-body {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .form {
    padding-Top: 28px;

    .change-detail {
      font-size: 12px;
      color: #0063a3;
      cursor: pointer;
    }
  }

  .form-group {
    align-items: baseline;
  }

  // .change-license {
  //   display: flex;
  //   justify-content: center;
  //   padding-bottom: 2%;
  //   align-items: center;

  //   .form-control {
  //     max-width: 30%;
  //   }
  // }

  h5 {
    color: rgba(0, 0, 0, 0.54);
    font-weight: normal;
    font-size: 13px;
  }

  h3 {
    padding-top: 2%;
    font-size: 14px;
    font-weight: 400;
    color: #252a2e;
  }

  .spinner {
    padding-top: 2px;
    // min-width: 29%;
    flex-grow: 1;
    height: 1rem; //matches the spinner height
  }


}