.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 112% */

  padding-top: 5%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  color: #00437b;
}
