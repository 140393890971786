@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

* {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

body {
    height: 100%;
    background-color: #f3f3f7;
}
.flex-container {
    min-height: 93vh;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    background-color: #f3f3f7;
}

.child-flex-container {
    justify-content: center;
    line-height: 75px;
    font-size: 30px;
    
}

.sideNav {
    flex: 20;
}

.mainApp {
    flex: 80;
}
