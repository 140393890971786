.res-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font: {
        family: 'Open Sans';
        style: normal;
        weight: 400;
        size: 24px;
    };
    color: rgba(0, 0, 0, 0.5);
    line-height: 18px;
    letter-spacing: 0.0015em;
    margin-top: 7.5rem;
}

.result-body{
    font-size: small;
    line-height: 3.125rem;
    height: calc(100% - 50px);
}

table {
    border: .125rem solid #E0E1E9;
    border-radius: .625rem;
    width: 100%;
    line-height: 1.875rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

td{
    border-bottom: .125rem solid #E0E1E9;
    padding-left:4px;
}

.result-title {
    font-weight: 700;
    font-size: large;
}

.message{
    line-height:1.5rem;
}

.recommendation-title {
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 0px;
}

.recommendation-content {
    font-weight: normal;
    line-height: 1.5rem;
}

ul {
    padding-left: 1.25rem;
}