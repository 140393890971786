h5.details {
  color: rgba(0, 0, 0, 0.54);
  font-weight: normal;
  font-size: 13px;
}
h3.details {
  padding-top:2%;
  font-size: 14px;
  font-weight: 400;
  color: #252a2e;
}
  