.card-flex {
    display: flex;
    flex-direction: row;
    height: 3%;
}
.ccard-title {
    justify-content: center;
    line-height: 20px;
    font-size: 20px;
}
.card-flex-container {
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.card-flex-content {
    justify-content: center;
    line-height: 5px;
}
.card-clickable:hover {
    cursor: pointer;
}

.card-button {
    margin: 0%;
    padding: 1%;
    text-align: center;
}