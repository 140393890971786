.sidenav {
  height: 100%;
  width: 100%;
  left: 0;
  background-color: #363545;
  overflow-x: hidden;
  font-size: 12px;
  padding: 0%;
  margin: 0%;
}
